@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body, #root  {
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: hsla(50 23.1% 94.9%);
}